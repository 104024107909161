"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.content = void 0;
exports.content = [
    {
        title: 'Symfony PHP apps',
        text: 'Symfony is robust and fast php framework. I have been using it for many years as it\'s perfectly crafted for backend solutions. No matter what is your goal, we @OpenRun will create custom, fully scalable application. We write great code for you to OPEN that app and RUN your business:) still keeping focus on important things.'
    },
    {
        title: 'Javascript/Ts',
        text: 'Javascript or Typescript code can add so much value to your business as a standalone solution. It performs also great as an addition to your PHP app. Even if you cannot see that cristal clear yet:). Make a good use of my 10y+ coding experience. Don\'t go too much into details for not to loose sight of the core. Just leave it to me.'
    },
    {
        title: 'Erlang',
        text: 'That is my personal goal for the future. To write an app in a very exquisite language, called Erlang. Probably the fastest and most stable of all languages. I would do it even for free:). Just because I dream of it. If you have a fixed idea - get in touch:)'
    },
];
