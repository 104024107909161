"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const animator_1 = require("./animator");
const data_1 = require("./data");
class PageRenderer {
    constructor() {
        this.currentPage = 0;
        this.totalPages = data_1.content.length;
        this.animator = null;
    }
    getRecord(index) {
        return data_1.content[index];
    }
    render() {
        this.animator = new animator_1.Animator(this.getRecord(this.currentPage), window.document.getElementById('title'), window.document.getElementById('text'), this.currentPage);
    }
    setCurrentPage(adjust) {
        var _a;
        (_a = this.animator) === null || _a === void 0 ? void 0 : _a.clear();
        this.currentPage = moduloPositive(this.currentPage + adjust, this.totalPages);
        this.render();
        /**
         *
         */
        function moduloPositive(n, m) {
            return ((n % m) + m) % m;
        }
    }
}
exports.default = PageRenderer;
