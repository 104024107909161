"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const eventHandler_1 = require("./eventHandler");
let handler;
function start() {
    if (!handler) {
        handler = new eventHandler_1.EventHandler();
    }
}
start();
