"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Animator = void 0;
class Animator {
    constructor(record, title, container, currentPage, params = {}) {
        this.params = {
            titlePrefix: 'title-id',
            elementPrefix: 'span-id',
            defaultColor: '#222',
            maxBrightness: 35,
            colorRange: { max: 13, min: 0 },
            blurTitleWidth: { max: 4.99, min: 2 },
            blurContentWidth: { max: 2.99, min: 1 },
            animateTime: { max: 2000, min: 500 },
            removeColorTime: { max: 20000, min: 12000 },
        };
        this.textLength = { max: 0, min: 0 };
        this.timer = 0;
        this.record = record;
        this.title = title;
        this.container = container;
        this.currentPage = currentPage;
        Object.assign(this.params, params);
        this.init();
    }
    /**
     * Prepare object, initial render and looping of animation
     */
    init() {
        this.refresh();
        this.animateContent();
        this.animateTitle();
    }
    /**
     * wraps line of text into span wrapper with id
     */
    wrap() {
        return `<span id="${this.params.titlePrefix}_${this.currentPage}" class="" style="filter: blur(0px)">${this.record.title}</span>`;
    }
    /**
     * Changes text delimited with spaces into array of span html elements
     */
    split() {
        let arr = this.record.text.split(' ');
        let splitContent = arr.map((el, i) => {
            return `<span 
                id="${this.params.elementPrefix}_${this.currentPage}_${i}"
                class="" 
                style="">
                    ${el} 
            </span>`;
        });
        this.textLength.max = splitContent.length;
        return splitContent;
    }
    /**
     * Rebuild container contents
     */
    refresh() {
        this.title.innerHTML = this.wrap();
        this.container.innerHTML = this.split().join(' ');
    }
    clear() {
        clearTimeout(this.timer);
    }
    animateTitle() {
        try {
            let el = document.getElementById(`${this.params.titlePrefix}_${this.currentPage}`);
            if (null === el) {
                return false;
            }
            el.style.transitionDuration = `${Math.floor(this.rand(this.params.animateTime)) / 1000}s`;
            el.classList.add('blurred');
            this.timer = setInterval(() => {
                if (el.style.filter === 'blur(0px)') {
                    el.style.filter = `blur(${Math.floor(this.rand(this.params.blurTitleWidth))}px)`;
                }
                else {
                    el.style.filter = 'blur(0px)';
                }
            }, this.rand(this.params.animateTime));
        }
        catch (error) {
            console.error(error);
            return false;
        }
        return true;
    }
    /**
     * Animation procedure
     */
    animateContent() {
        try {
            let elementId = Math.floor(this.rand(this.textLength));
            let el = document.getElementById(`${this.params.elementPrefix}_${this.currentPage}_${elementId}`);
            if (null === el) {
                return false;
            }
            el.style.transitionDuration = `${Math.floor(this.rand(this.params.animateTime)) / 1000}s`;
            el.className = 'blurred';
            el.style.color = this.randColor();
            el.style.filter = `blur(${Math.floor(this.rand(this.params.blurContentWidth))}px)`;
            setTimeout(() => {
                el.className = '';
            }, this.rand(this.params.animateTime));
            setTimeout(() => {
                this.animateContent();
            }, this.rand(this.params.animateTime));
            setTimeout(() => {
                el.style.filter = 'blur(0px)';
            }, this.rand(this.params.animateTime));
            setTimeout(() => {
                el.style.color = this.params.defaultColor;
            }, this.rand(this.params.removeColorTime));
        }
        catch (error) {
            console.error(error);
            return false;
        }
        return true;
    }
    /**
     * Creates random color as #RGB hex (not brighter that custom factor params.maxBrightness)
     */
    randColor() {
        let arr = [];
        while (true) {
            for (let i = 0; i < 6; i++) {
                arr[i] = Math.floor(this.rand(this.params.colorRange)).toString(16);
            }
            // It cannot be too bright
            if (parseInt(arr[0], 16) + parseInt(arr[2], 16) + parseInt(arr[4], 16) < this.params.maxBrightness) {
                break;
            }
        }
        return '#' + arr.join('');
    }
    /**
     * Returns random number from range
     * @param min
     * @param max
     */
    rand({ max = 0, min = 0 }) {
        return min + Math.random() * (max - min);
    }
}
exports.Animator = Animator;
